
import { Component, Mixins } from 'vue-property-decorator';
import { MyTeamsMixin, VuetifyMixin } from '../../mixins';
import TeamMiniCard from '../ui/TeamMiniCard.vue';
import { TeamModel } from '../../models/team';

@Component({
	components: { 
		TeamMiniCard
	},
})
export default class TeamList extends Mixins(MyTeamsMixin, VuetifyMixin){

	expanded: boolean = false;
	numToShow = this.IsLargeScreen? 4 : 2;

	get TeamsToShow(): TeamModel[] {
		return this.ShowAll? this.MyTeams : this.MyTeams.slice(0, this.numToShow);
	}

	get ShowAll(): boolean {
		return this.NumTeams <= this.numToShow || this.expanded;
	}

	get ShowMore(): boolean {
		return this.MyTeams.length > this.numToShow && !this.expanded;
	}

	get ShowLess(): boolean {
		return this.MyTeams.length > this.numToShow && this.expanded;
	}

	openTeamDashboard(teamId){
		this.$router.push({ name: 'TeamDashboard', params: { teamId }})
	}
}
