
import { Component, Mixins, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { GameReportModel } from "@/models";
import { gameReportApi } from "@/api/GameReportApi";
import { VuetifyMixin, MyCoachMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin } from "@/mixins";
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { DataTableHeader } from 'vuetify';
import { TeamModel } from "@/models/team";
import { teamApi } from "@/api/TeamApi";
import { CoachGameReport } from '@/../types/constants/web_client_user.routes';

@Component({
	components: {
		Page,
	},
})
export default class CoachGameReportsSummary extends Mixins(VuetifyMixin, MyCoachMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin) {
    @Prop() teamId: string;
    @Prop() action: string;

    search: string = '';
    loadedGameReports: boolean = false;
    gameReports: Array<GameReportModel> = [];

    mounted() {
        if( this.IsNotEmpty(this.action) && this.action.trim().toLowerCase().startsWith("new") ) this.onNewGameReport();
        this.tableOptions.sortBy = ['date'];
        this.tableOptions.sortDesc = [true];
    }
    async loadTable() {
        this.loadedGameReports = false;

		const query: RepositoryQuery<GameReportModel> = {
			$match: { coachId: this.MyCoachId } 
		}
        if( this.IsNotEmpty(this.teamId) ) { query.$match = { ...query.$match, teamId: this.teamId } }
		const options: QueryOptions = {
			sort: {
				fields: [
					{ field: 'date', desc: true }
				]
			}
		};
		const response: PaginatedResponse<GameReportModel> = await gameReportApi.queryAll(query, options);
        this.gameReports = response.docs;

        this.loadAllTeams();
        this.loadedGameReports = true;
    }
	get PageLoading(): boolean {
        return !this.MyCoachIsReady || !this.loadedGameReports || !this.loadedTeams;
    }
	get TableLoading(): boolean {
		return !this.loadedGameReports || !this.loadedTeams;
	}
	get TotalItems(): number {
		return this.gameReports.length;
	}

    get TableHeaders(): Array<DataTableHeader<any>> {
        return [
            { text: 'Date', value: 'date', sortable: true, },
            { text: 'Title', value: 'title', sortable: false, },
            { text: 'Team', value: 'team', sortable: true, },
            { text: 'Location', value: 'locationId', sortable: false, },
            { text: '', value: 'actions', sortable: false },
            { text: '', value: 'data-table-expand' },
        ] 
    }

    teams: Array<TeamModel> = [];
    loadedTeams: boolean = false;
    async loadAllTeams() {
        this.loadedTeams = false;
        for( const report of this.gameReports ) {
            // if no team is not assocated with this report
            if( this.IsEmpty(report.teamId) ) continue;

            const team = await this.teams.find(t => t.id == report.teamId);
            // if team already in local cache
            if( this.IsNotEmpty(team) ) continue;

            // retrieve team information from database
            const dbTeam = await teamApi.findById(report.teamId);
            if( this.IsNotEmpty(dbTeam) ) this.teams.push(dbTeam);
        }
        this.loadedTeams = true;
    }
    TeamName(teamId: string): string {
        const team = this.teams.find(t => t.id === teamId)
        if( this.IsEmpty(team) ) return '';
        return team.name;
    }

    onNewGameReport() {
		this.$router.push({
			name: CoachGameReport,
			params: {
				teamId: this.teamId,
			}
		});        
    }
    onOpenGameReport(report: GameReportModel) {
		this.$router.push({
			name: CoachGameReport,
			params: {
				reportId: report.id,
			}
		});        
    }

}

