
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import CircledAvatar from '../ui/CircledAvatar.vue';
import ContextMenu from '../ui/ContextMenu.vue';
import { DataTableHeader, DataSortFunction } from 'vuetify';
import { VuetifyMixin, DataTableSortMixin, DebounceMixin, BAIconsMixin } from '../../mixins';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { formatDatePrettyShort, formatDateHyphensYYYYMMDD } from '@/helpers/date';
import CalendarProvider, { GetGameResultIdFn } from '@/components/hoc/CalendarProvider.vue';
import FinalScoreBox from '@/components/teams/FinalScoreBox.vue';
import CalendarMonthSelector from '@/components/calendar/CalendarMonthSelector.vue';
import { GameResultModel } from '@/models/calendar/GameResultModel';
import { GameResultApi } from '@/api/GameResultApi';
import { TeamEventType, SoccerGameTiming } from '@/../types/enums';

type Game = {
	event: CalendarEventModel;
	gameResult: GameResultModel | null;
}

@Component({
	components: { CalendarProvider, CalendarMonthSelector, CircledAvatar, ContextMenu, FinalScoreBox },
})
export default class TeamGames extends Mixins(DataTableSortMixin, DebounceMixin, VuetifyMixin, BAIconsMixin) {
	formatDatePrettyShort = formatDatePrettyShort;

	@Prop({ default: 'baColorDeepBlue' }) color: string;
	@Prop({ default: true, type: Boolean }) headerPadding: boolean;
	@Prop({ default: true, type: Boolean }) readOnly: boolean;
	@Prop({ default: 660 }) tableHeight: number;
	@Prop({ required: true }) calendarId: string;
	@Prop({ required: true }) teamId: string;
	@Prop({ required: true }) teamEvents: CalendarEventModel[];
	@Prop({ required: true }) getGameResultId: GetGameResultIdFn;
	@Prop({ required: true, default: formatDateHyphensYYYYMMDD(new Date()) }) focusDate: string;
	updateFocusDate(focusDate: string): void{
		this.$emit('update:focus-date', focusDate);
	}

	loading: boolean = false;

	formatGameTiming(timing: SoccerGameTiming): string{
		if(timing === SoccerGameTiming.Overtime) return "OT";
		if(timing === SoccerGameTiming.FullTime) return "FT";
		return "FT";
	}

	searchLineups: string = "";

	get headers(): DataTableHeader<any>[]{
		const headers: DataTableHeader<any>[] = [
			{
				text: 'Date',
				value: 'event.start',
				sortable: true,
			},
			{
				text: 'Opponent',
				value: 'event.name',
				sortable: false,
			},
			{
				text: 'Outcome',
				value: 'gameResult.Outcome',
				sortable: false,
			},
		];
		if(!this.XSmallScreen){
			headers.push({
				text: '',
				value: 'data-table-controls'
			});
		}
		return headers;
	}

	get XSmallScreen(): boolean{
		return this.$vuetify.breakpoint.xsOnly;
	}

	async created(): Promise<void>{
		await this.debounceLoadEventGameResults();
	}

	@Watch('teamEvents')
	async debounceLoadEventGameResults(): Promise<void>{
		this.loading = true;
		this.debounceCallback('loadEventGameResults', () => this.loadEventGameResults(), 1000);
	}
	async loadEventGameResults(): Promise<void>{
		this.games = await Promise.all(this.teamEvents
			.filter(event => event.eventType === TeamEventType.Game)
			.map(async event => ({
				event,
				gameResult: await this.loadGameResult(event),
			}))
		);
		this.loading = false;
	}
	async loadGameResult(event: CalendarEventModel): Promise<GameResultModel>{
		return new GameResultApi('team', this.teamId, this.calendarId, event.RootId).findById(this.getGameResultId(event, event.start));
	}

	games: Game[] = [];
	get Games(): Game[]{
		return this.games
			.sort((a,b) => +a.event.start - +b.event.start);
	}


	get TableStyle(): Record<string, string>{
		return {
			'overflow-y': 'auto',
			'max-height': `${this.tableHeight}px`,
		}
	}

	eventSort: DataSortFunction<Game> = (items: Game[], sortBy: string[], sortDesc: boolean[]) => {
		if(sortBy.length === 0) return items;
		const [field] = sortBy;
		const [desc] = sortDesc;
		switch(field){
		case 'event.start':
			return items.sort((a, b) => {
				if (+a.event.start > +b.event.start) {
					return desc ? -1 : 1;
				} else if (+a.event.start < +b.event.start) {
					return desc ? 1 : -1;
				} else {
					return 0;
				}
			});
		default:
			return this.genericSort<Game>(field, items, desc);
		}
	}

}
