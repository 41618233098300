
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, TeamDashboardMixin, MyCoachMixin, StringsMixin, DebounceMixin, BAIconsMixin, CoachRoutingMixin} from "@/mixins";
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { scoutingObservationApi } from '@/api/ScoutingObservationApi'
import { athleteApi } from "@/api/AthleteApi";
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { ScoutingReportScore, TeamEventType } from "@/../types";
import { GameReportModel, ScoutingObservationModel, AthleteProfileModel } from "@/models";
import { PlayerOnTeam, TeamModel } from "@/models/team";
import { gameReportApi } from "@/api/GameReportApi";
import { teamApi } from "@/api/TeamApi";
import { CalendarApi, CalendarEventsApi } from '@/api/CalendarApi';
import * as Routes from '@/../types/constants/web_client_user.routes';
import DatePicker from "@/components/forms/DatePicker.vue";
import TimePickerDialog from "@/components/calendar/TimePickerDialog.vue";
import { getTime } from "@/helpers/date";
import { CoachGameReportsSummary } from '@/../types/constants/web_client_user.routes';
import { AthleteAutocompleteInfo } from '@best-athletes/ba-types';
import AthleteAutocomplete from "@/components/forms/AthleteAutocomplete.vue";

@Component({
	components: {
		Page,
		DatePicker,
		TimePickerDialog,
		AthleteAutocomplete,
	},
})
export default class CoachGameReport extends Mixins(
		VuetifyMixin, 
		MyCoachMixin,
		CoachRoutingMixin, 
		StringsMixin, 
		TeamDashboardMixin, 
		DebounceMixin, 
		BAIconsMixin,
	) {
	@Prop({type: String, default: undefined}) reportId;
	@Prop({type: String, default: undefined}) teamId;
	@Prop({type: String, default: undefined}) eventId;

	ScoutingReportScore = ScoutingReportScore;

	gameReport: GameReportModel;
	team: TeamModel;
	useAllTeams: boolean = false;
	coachTeams: Array<TeamModel> = [];
	allTeams: Array<TeamModel> = [];
	teamGames: Array<CalendarEventModel> = [];
	loadedGameReport: boolean = false;
	loadedTeam: boolean = false;
	loadedCoachTeams: boolean = false;
	loadedAllTeams: boolean = false;
	loadedTeamGames: boolean = false;
	teamEvent: CalendarEventModel;
	teamCalendarId: string;
	dirty: boolean = false;

	beforeRouteLeave(to, from, next) {
		if( this.dirty ) {
			const confirmed = window.confirm('You have unsaved changes. Are you sure you want to leave?');
			if( !confirmed ) {
				next(false);
				return;
			}
		}
		next();
	}

	get IsLoading(): boolean {
		return !this.MyCoachIsReady || !this.loadedGameReport || !this.loadedTeam || !this.loadedTeamGames;
	}
	get IsNewReport(): boolean {
		return this.IsEmpty(this.reportId);
	}
	get IsReportNoTeam(): boolean {
		return this.IsNotEmpty(this.gameReport) && this.IsNotEmpty(this.gameReport.id) && this.IsEmpty(this.gameReport.teamId)
	}
	get HasTeam(): boolean {
		if( !this.loadedTeam ) return false;
		return this.IsNotEmpty(this.team);
	}
	get HasGames(): boolean {
		if( !this.loadedTeamGames ) return false;
		return this.IsNotEmpty(this.teamGames)
	}
	get TeamName(): string {
		if( !this.HasTeam ) return "No team specified";
		return this.team.name;
	}
	gamePlayersSorted: Array<PlayerOnTeam> = [];
	noSort: boolean = false;
	get HasTeamPlayers(): boolean {
		if( !this.HasTeam ) return false;
        return this.IsNotEmptyArray(this.team.players);
	}

	sortingPlayers: boolean = false;
	sortPlayers(): Array<PlayerOnTeam> {
		this.sortingPlayers = true;
		var allPlayers: Array<PlayerOnTeam> = [];
		if( this.IsNotEmptyArray(this.guestPlayers) ) allPlayers.push(...this.guestPlayers);
		if( this.HasTeam ) allPlayers.push(...this.team.players);
        if( this.IsEmptyArray(allPlayers) ) return [];
		if( this.noSort ) return allPlayers;

		this.gamePlayersSorted = allPlayers.slice();
        this.gamePlayersSorted.sort((a, b) => {
			var numberA = Number(a.number);
			var numberB = Number(b.number);
			if( this.IsEmpty(numberA) ) numberA = 999;
			if( this.IsEmpty(numberB) ) numberB = 999;
			return( numberA - numberB );
        });
		this.sortingPlayers = false;
		
		return this.gamePlayersSorted;
	}
	get GamePlayers(): Array<PlayerOnTeam> {
		if( this.IsNotEmptyArray(this.gamePlayersSorted) ) return this.gamePlayersSorted;
		return this.sortPlayers();
	}
	get JerseySize(): number {
		if( this.IsMobile ) return 5;
		return 7;
	}
	get PhotoSize(): number {
		if( this.IsMobile ) return 25;
		return 40;
	}
	get EarliestTime(): Date {
		if( this.IsEmpty(this.gameReport.date) ) this.gameReport.date = new Date();
		return new Date(`${this.formatDateSlashesYYYYMMDD(this.gameReport.date)} 7:00 am`);
	}
	GetObservation(athleteId: string): ScoutingObservationModel {
		const observation = this.scoutingObservations.find(o => o.athleteId === athleteId);
		if( this.IsNotEmpty(observation) ) return observation;
		return new ScoutingObservationModel( { athleteId, coachId: this.gameReport.coachId, eventId: this.gameReport.eventId } )
	}
	HasObservation(athleteId: string): boolean {
		return this.IsNotEmpty(this.GetObservation(athleteId));
	}
	IsLiked(athleteId: string): boolean {
		if( !this.HasObservation(athleteId) ) return false;
		return this.ObservationRating(athleteId) >= ScoutingReportScore.Score4;
	}
	IsDisliked(athleteId: string): boolean {
		if( !this.HasObservation(athleteId) ) return false;
		return this.ObservationRating(athleteId) <= ScoutingReportScore.Score2;
	}
	ObservationRating(athleteId: string): ScoutingReportScore {
		if( !this.HasObservation(athleteId) ) return undefined;
		const observation = this.GetObservation(athleteId);
		return observation.rating;
	}
	SortRating(athleteId: string): number {
		if( !this.HasObservation(athleteId) ) return ScoutingReportScore.Score3;
		const observation = this.GetObservation(athleteId);
		if( this.IsEmpty(observation.rating) ) return ScoutingReportScore.Score3;
		return observation.rating;
	}
	ObservationNotes(athleteId: string): string {
		if( !this.HasObservation(athleteId) ) return '';
		const observation = this.GetObservation(athleteId);
		return observation.notes;
	}
	ThumbUpIcon(rating: ScoutingReportScore): string {
		if( this.IsNotEmpty(rating) && rating >= ScoutingReportScore.Score4 ) return this.BAIcons.like;
		return this.BAIcons.likeOutline;
	}
	ThumbDownIcon(rating: ScoutingReportScore): string {
		if( this.IsNotEmpty(rating) && rating <= ScoutingReportScore.Score2 ) return this.BAIcons.dislike;
		return this.BAIcons.dislikeOutline;
	}
	ThumbUpColor(rating: ScoutingReportScore): string {
		if( this.IsNotEmpty(rating) ) {
			if( rating >= ScoutingReportScore.Score4 ) return "green";
			if( rating < ScoutingReportScore.Score3 ) return "light-blue lighten-5 black--text";
		}
		return "primary";
	}
	ThumbDownColor(rating: ScoutingReportScore): string {
		if( this.IsNotEmpty(rating) ) {
			if( rating <= ScoutingReportScore.Score2 ) return "red";
			if( rating > ScoutingReportScore.Score3 ) return "light-blue lighten-5 black--text";
		}
		return "primary";
	}
	IsUpdatingThumbUp(athleteId: string): boolean {
		return this.updatingThumbUp && ( athleteId === this.currentObservationAthlete );
	}

	mounted() {
		this.initialize();
	}
	private async initialize() {
		await this.loadGameReport();
		await this.loadCoachTeams();
		await this.loadAllTeams();
		await this.loadGameReportAthletes();
		await this.loadTeam();

		if( this.IsEmpty(this.gameReport) ) return;
		if( this.IsEmpty(this.gameReport.date) ) this.gameReport.date = new Date();
		this.onDateUpdated();
	}
	async loadGameReport() {
		this.loadedGameReport = false;
		if( this.IsEmpty(this.reportId) ) {
			this.gameReport = new GameReportModel({
				coachId: this.MyCoachId,
				teamId: this.teamId,
				eventId: this.eventId,
				title: this.IsEmpty(this.teamId)? "New Game Report" : `New Game Report for Team`,
			})
		} else {
			this.gameReport = await gameReportApi.findById(this.reportId);
			this.onDateUpdated();
		}
		this.loadedGameReport = true;
	}
	guestPlayers: Array<PlayerOnTeam> = [];
	async getGuestPlayer(athleteId: string): Promise<PlayerOnTeam> {
		const guestPlayerProfile: AthleteProfileModel = await athleteApi.getPublicProfile(athleteId);
		if( this.IsEmpty(guestPlayerProfile) ) return undefined;
		return {
			athleteId: athleteId,
			email: guestPlayerProfile.email,
			firstName: guestPlayerProfile.firstName,
			lastName: guestPlayerProfile.lastName,
			pictureUrl: guestPlayerProfile.pictureUrl,
			number: '',
			gender: guestPlayerProfile.gender,
			injured: false,
			eligibleToPlay: true,
			gradYear: guestPlayerProfile.GradYear,
			dateOfBirth: guestPlayerProfile.BirthDate,
		}
	}
	async addGuestPlayer(athleteId: string) {
		if( this.IsEmpty(athleteId) ) return;										// player not identified
		if( this.guestPlayers.findIndex(p => p.id === athleteId ) >= 0 ) return;	// duplicate player

		const guestPlayer = await this.getGuestPlayer(athleteId);
		if( this.IsNotEmpty(guestPlayer) ) this.guestPlayers.push(guestPlayer);
	}
	async loadGameReportAthletes() {
		if( this.IsEmpty(this.gameReport) ) return;
		if( this.IsEmptyArray(this.gameReport.athletes) ) return;

		for( const athleteId of this.gameReport.athletes ) {
			await this.addGuestPlayer(athleteId);
		}
	}
	async loadCoachTeams() {
		this.loadedCoachTeams = false;
		this.coachTeams = await teamApi.findByCoachId({coachId: this.gameReport.coachId})
		this.loadedCoachTeams = true;
	}
	async loadAllTeams() {
		this.loadedAllTeams = false;
		if( this.IsEmptyArray(this.coachTeams) ) this.useAllTeams = true;
		this.loadedAllTeams = true;
	}
	async loadTeam() {
		this.loadedTeam = false;
		if( this.IsEmpty(this.gameReport) ) {
			await this.loadGameReport();
		}
		if( this.IsNotEmpty(this.gameReport.teamId) ) {
			this.team = await teamApi.findById(this.gameReport.teamId);
		}
		this.loadedTeam = true;

		await this.loadTeamGames();
		await this.loadScoutingObservations();
	}
	async loadTeamGames() {
		if( this.useAllTeams ) {
			// CCM_TODO_CALENDAR: if the coach owns the team, use team calendar

			// ensure that this event has a unique id
			this.SetEventId();
			this.loadedTeamGames = true;
			return;
		}

		this.loadedTeamGames = false;
		if( this.IsNotEmpty(this.team) ) {
			const calendarApi = new CalendarApi('team', this.team.id);
			const response = await calendarApi.findAllWithAccess();
			if( this.IsNotEmptyArray(response) ) {
				var teamEvents: Array<CalendarEventModel> = [];
				for( const calendar of response ) {
					this.teamCalendarId = calendar.id;
					const eventsApi = new CalendarEventsApi('team', this.team.id, calendar.id);
					const events = await eventsApi.findAllWithAccess();
					if( this.IsNotEmptyArray(events) ) teamEvents = teamEvents.concat(events);
				}
				this.teamGames = teamEvents.filter(e => e.eventType === TeamEventType.Game );
			}
		}
		if( this.IsNotEmpty(this.gameReport.eventId) ) {
			this.gameReport.coachId = this.MyCoachId;
			this.gameReport.teamId = this.team?.id;
			this.gameReport.opponent = "TODO: Load Game Result";
			// this.gameReport.athletes =
			// this.gameReport.observations =
			this.teamEvent = this.teamGames.find(g => g.id === this.gameReport.eventId );
			if( this.IsNotEmpty(this.teamEvent) ) {
				this.gameReport.eventId = this.teamEvent.id;
				this.gameReport.date = new Date(this.teamEvent.start);
				this.gameReport.locationId = this.teamEvent.venue;
				this.SetIfEmpty( this.gameReport.title, this.teamEvent.EventName );
			}
		}
		this.loadedTeamGames = true;
	}

	addingAthlete: boolean = false;
	athleteToAddId: string;
	athleteToAddInfo: AthleteAutocompleteInfo | null = null;
	athleteToAddLoading: boolean = false;
	creatingNewAthlete: boolean = false;
	async onAddAthlete() {
		this.addingAthlete = true;
	}
	async onCreateNewAthlete() {
		this.creatingNewAthlete = !this.creatingNewAthlete;
	}
	async onAddAthleteCancel() {
		this.athleteToAddId = undefined;
		this.athleteToAddInfo = null;
		this.addingAthlete = false;
	}
	async onAddAthleteOK() {
		if( this.IsEmptyArray(this.gameReport.athletes) ) this.gameReport.athletes = [];
		this.gameReport.athletes.push(this.athleteToAddId);
		this.addGuestPlayer(this.athleteToAddId);
		this.onMarkDirty(true);
		this.athleteToAddId = undefined;
		this.athleteToAddInfo = null;
		this.addingAthlete = false;
	}
	@Watch('athleteToAddInfo')
	async loadAthleteDetails(athleteLink: AthleteAutocompleteInfo | null, oldAthleteLink: AthleteAutocompleteInfo | null): Promise<void>  {
		if( this.IsEmpty(athleteLink) ) return this.resetNewAthleteForm();
		if( athleteLink.id === oldAthleteLink?.id ) return;
		this.athleteToAddLoading = true;
		await new Promise(r => setTimeout(r, 1000));
		this.athleteToAddId = this.athleteToAddInfo.id;
		this.athleteToAddLoading = false;
	}
	async resetNewAthleteForm() {
	}

	async onMarkDirty(force: boolean = false) {
		if( this.IsNewReport && !force ) return;
		this.debounceCallback('dirty', () => { this.onSetDirty() }, 1000)
	}
	async onSetDirty() {
		this.dirty = true;
	}
	async SetEventId() {
		if( this.IsNotEmpty(this.gameReport.eventId) ) return;
		const { uuid } = await gameReportApi.uuid();
		this.gameReport.eventId = uuid;
	}
	async onSaveReport(goToSummary: boolean = true) {
		if( !this.gameReportDate ) {
			this.gameReportDate = new Date();
			await this.onDateUpdated()
		}
		this.gameReport.date = new Date(`${this.formatDateSlashesYYYYMMDD(this.gameReportDate)} ${this.gameReportTime}`);
		this.SetEventId();
		await gameReportApi.save(this.gameReport);
		this.dirty = false;
		if( goToSummary ) this.gotoGameReportSummary();
	}

	gotoGameReportSummary() {
		this.$router.push({
			name: Routes.CoachGameReportsSummary,
			params:{
				...this.$route.params,
			}
		});
	}

	gameReportDate: Date;
	gameReportTime: string;
	async onDateUpdated() {
		this.gameReportDate = this.gameReport.date;
		this.gameReportTime = getTime(this.gameReport.date);
	}
	async onSelectGame() {
		this.loadedGameReport = false;
		this.gameReport.eventId = this.teamEvent.id;
		await this.loadScoutingObservations();
		await this.onDateUpdated();
		this.loadedGameReport = true;
	}
	async onDeselectTeam() {
		this.teamId = undefined;
		this.gameReport.teamId = undefined;
		this.team = undefined;
		await this.loadTeam();
	}
	async onSelectTeam() {
		this.teamId = this.team.id;
		this.gameReport.teamId = this.teamId;
		await this.loadTeam();
	}
	async onCreateNewGame() {
		this.$router.push({
			name: Routes.TeamEventCreate,
			params: {
				teamId: this.teamId,
			},
		});
	}
    async onNewGameReport() {
		this.$router.push({
			name: CoachGameReportsSummary,
			params: {
				teamId: this.teamId,
				action: 'new',
			}
		});        
    }

	loadedScoutingObservations: boolean = false;
	scoutingObservations: Array<ScoutingObservationModel> = [];
	async loadScoutingObservations() {
		this.loadedScoutingObservations = false;
		
		this.scoutingObservations = [];
		if( this.IsNotEmpty(this.gameReport.coachId) && this.IsNotEmpty(this.gameReport.eventId)) {
			for( const player of this.GamePlayers ) {
				var observations = await this.findObservationsByAthleteId(player.athleteId);
				if( this.IsNotEmpty(observations) ) this.scoutingObservations.push(...observations);
			}
		}
		this.loadedScoutingObservations = true;
	}
	async findObservationsByAthleteId(athleteId: string): Promise<Array<ScoutingObservationModel>> {
		if( this.IsEmpty(athleteId)) return;
		if( this.IsEmpty(this.gameReport)) return;
		
		const query: RepositoryQuery<ScoutingObservationModel> = {
			$match: {
				athleteId: athleteId,
				coachId: this.gameReport.coachId,
				eventId: this.gameReport.eventId,
			}
		}
		const options: QueryOptions = {
			sort: {
				fields: [
					{ field: 'date', desc: true }
				]
			}
		};
		const response: PaginatedResponse<ScoutingObservationModel> = await scoutingObservationApi.queryAll(query, options);
		if( this.IsEmpty(response.count) ) return undefined;
		return response.docs;
	}
	updatingRating: boolean = false;
	updatingNotes: boolean = false;
	updatingObservation: boolean = false;
	updatingThumbUp: boolean = false;
	updatingThumbDown: boolean = false;
	currentObservationAthlete: string;
	async saveObservation(observation: ScoutingObservationModel): Promise<ScoutingObservationModel> {
		if( this.IsEmpty(observation) ) return;

		this.SetEventId();
		this.currentObservationAthlete = observation.athleteId;
		this.updatingObservation = true;
		const isNewObs = this.IsEmpty( observation.id );
		observation.date = this.gameReport.date;
		const newObs = await scoutingObservationApi.save(observation);
		if( isNewObs ) {
			this.scoutingObservations.push(newObs);
		}
		this.currentObservationAthlete = undefined;
		this.updatingObservation = false;
		return newObs;
	}
	async onSetThumbUp(athleteId: string) {
		this.currentObservationAthlete = athleteId;
		this.updatingThumbUp = true;
		await this.onSetRating(athleteId, ScoutingReportScore.Score4);
		this.updatingThumbUp = false;
		this.currentObservationAthlete = undefined;
	}
	async onSetThumbDown(athleteId: string) {
		this.currentObservationAthlete = athleteId;
		this.updatingThumbDown = true;
		await this.onSetRating(athleteId, ScoutingReportScore.Score2);
		this.updatingThumbDown = false;
		this.currentObservationAthlete = undefined;
	}
	async onSetRating(athleteId: string, rating: ScoutingReportScore) {
		this.noSort = true;
		this.updatingRating = true;
		var observation = await this.GetObservation(athleteId);
		if( observation.rating === rating ) rating = ScoutingReportScore.Score3;
		observation.rating = rating;
		await this.saveObservation(observation);
		this.updatingRating = false;
	}
	async onChangeNotes(athleteId: string, notes: string, force: boolean = false ) {
		this.updatingNotes = true;
		var observation = this.GetObservation(athleteId);
		observation.notes = notes;
		await this.debounceCallback('scoutingObservation', () => { this.saveObservation(observation) }, force? 0 : 2500);
		this.updatingNotes = false;
	}

	creatingObservation: boolean = false;
	viewingObservations: boolean = false;
	currentAthlete: string;
	athleteObservations: Array<ScoutingObservationModel>;
	async onCreateNewObservation(athleteId: string) {
		this.currentAthlete = athleteId;
		this.creatingObservation = true;
	}
	async onViewObservations(athleteId: string) {
		this.currentAthlete = athleteId;
		this.viewingObservations = true;
		this.athleteObservations = await this.findObservationsByAthleteId(athleteId);
	}

	teamSearch: string = "";
	async onToggleAllTeams() {
	}
	async onChangeTeamSearch() {

	}
	@Watch('teamSearch') debounceSearch(): void{
		this.debounceCallback('execTeamSearch', this.execTeamSearch.bind(this), 150);
	}
	async execTeamSearch(): Promise<void>{
		this.loadedAllTeams = false;
		try{
			const res = await teamApi.queryAll({
				search: this.teamSearch,
				fields: ['name'],
			},
			{
				limitPerPage: 5,
			});
			this.allTeams = res.docs;
		}catch(e){
			console.error(`Failed to query teams:`, e);
		}finally{
			this.loadedAllTeams = true;
		}
	}

	async onPlayerUpdated(player: PlayerOnTeam) {
		this.noSort = true;
	}
}
