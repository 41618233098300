
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, MyCoachMixin, StringsMixin, BAIconsMixin, SportsSelectorMixin, DominantSideSelectorMixin, AthleteApiMixin } from "@/mixins";
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import { AthleteAutocompleteInfo } from '@best-athletes/ba-types';
import { ScoutingReportModel } from "@/models/scoutingReport/ScoutingReportModel";
import { AthleteProfileModel } from "@/models";
import { SportModel } from "@/models/sport";
import { PlayerOnTeam, TeamModel } from "@/models/team";
import { ConfirmationDialog } from "@/components/ui/ConfirmationDialog.vue";
import AthleteAutocomplete from "@/components/forms/AthleteAutocomplete.vue";
import { scoutingReportApi } from '@/api/ScoutingReportApi';
import { athleteApi } from "@/api/AthleteApi";
import { teamApi } from "@/api/TeamApi";
import { notificationStore } from '@/store';
import { PlayerTypeValues } from "@/../types";
import ScoreSelector from '@/components/forms/ScoreSelector.vue';

@Component({
	components: {
		ConfirmationDialog,
		ScoreSelector
	},
})
export default class CoachAthleteReport extends Mixins(
		VuetifyMixin,
		StringsMixin,
		BAIconsMixin,
		MyCoachMixin,
		ScoutingReportScoreSelectorMixin,
		SportsSelectorMixin,
		DominantSideSelectorMixin,
		AthleteApiMixin,
		AthleteAutocomplete,
	){
	@Prop() reportId: string;
	@Prop() athleteId: string;
	@Prop() teamId: string;
	@Prop() disabled: boolean;

	PlayerTypeValues = PlayerTypeValues;

	scoutingReport: ScoutingReportModel;

	isAthleteLoaded = false;
	isAthleteLoading = false;
	isAthleteLinking = false;
	athleteLink: AthleteAutocompleteInfo;
	athleteProfile: AthleteProfileModel;
	team: TeamModel;
	playerOnTeam: PlayerOnTeam;
	sport: SportModel = new SportModel();

	isReportInitialized: boolean = false;
	isReportLoaded: boolean = false;
	reportChanged: boolean = false;

	isYouth: boolean = true;

	YouthLevels = [
		'1 - International',
		'2 - Pro academy',
		'3 - High performance',
		'4 - Competitive',
		'5 - Pre competitive',
	];
	SeniorLevels = [
		'1 - International Top Leagues',
		'2 - Secondary pro leagues',
		'3A - University',
		'3B - League 1',
		'3C - USL League 2',
		'4 - Senior competitive',
		'5 - Senior non competitive',
	]
	get CompetitveLevelItems(): string[] {
		if( this.isYouth ) return this.YouthLevels;
		return this.SeniorLevels;
	}

	mounted() {
		this.loadAthlete();
		this.initializeReport();
	}
	async initializeReport() {
		this.isReportInitialized = false;
		if( this.IsEditingReport ) {
			try {
				await this.loadReport();
			} catch(e) {
				this.errorMessage = e;
				notificationStore.pushErrorNotificationMessage(`Error initializing Scouting Report: ${e}`);
			}
		} else {
			this.scoutingReport = new ScoutingReportModel();
			this.isReportLoaded = true;
		}
		this.isReportInitialized = true;
	}
	async loadReport() {
		this.isReportLoaded = false;
		try {
			if( this.IsNotEmpty( this.reportId ) ) {
				if( this.IsEmpty( this.athleteId ) ) {
					this.scoutingReport = await scoutingReportApi.findById( this.reportId );
				} else {
					this.scoutingReport = await scoutingReportApi.findByAthleteId( this.athleteId, this.reportId );
				}
			}
		} catch(e) {
			this.errorMessage = e;
			notificationStore.pushErrorNotificationMessage(`Error loading Scouting Report: ${e}`);
		}
		this.isReportLoaded = true;
	}

    errorMessage: string = null;
    get IsError(): boolean {
        return this.IsNotEmpty(this.errorMessage);
    }
	get IsEditingReport(): boolean {
		return this.IsNotEmpty(this.reportId);
	}
	get IsLoading(): boolean {
		return !this.isReportInitialized || !this.isReportLoaded;
	}

    submitDialogVisible: boolean = false;
	async initalSubmit(validate: () => Promise<boolean>): Promise<void> {
		const isValid = await validate();
		if (!isValid) {
			this.errorMessage = 'Please ensure all required fields and scores for the evaluation matrix are provided.';
			this.$vuetify.goTo(0);
		}
		else {
			this.submitDialogVisible = true;
		}
	}

	get IsAthleteReady(): boolean {
		if( this.isAthleteLinking ) return false;
		return this.isAthleteLoaded;
	}
	get IsAthleteLinked(): boolean {
		return this.IsNotEmpty(this.athleteId);
	}
	get AthleteName(): string {
		if( !this.IsAthleteReady ) return '';
		return this.athleteProfile.FullName;
	}
	async loadAthlete() {
		this.isAthleteLoaded = false;
		this.isAthleteLoading = true;
		if( this.IsNotEmpty(this.athleteId) ) {
			this.athleteProfile = await athleteApi.findById(this.athleteId);
			await this.loadTeam();
			await this.updateReport();
		}
		await this.loadSport();
		this.isAthleteLoading = false;
		this.isAthleteLoaded = true;
	}
	async loadTeam() {
		if( this.IsEmpty(this.athleteProfile) ) return;

		if( this.IsNotEmpty(this.teamId) ) {
			this.team = await teamApi.findById(this.teamId);
		} else {
			this.team = await teamApi.findCurrentByAthleteId(this.athleteProfile.id);
		}

		if( this.IsEmpty(this.team) ) return;

		this.playerOnTeam = this.team.players.find(p => p.athleteId === this.athleteId);
	}
	async loadSport() {
		if( this.IsEmpty(this.athleteProfile) ) return;

		this.sport = this.athleteProfile.Sport;
	}
	async updateReport() {
		this.scoutingReport = new ScoutingReportModel();
		if( this.IsEmpty(this.athleteProfile) ) return;

		this.scoutingReport.playerDetails.firstName = this.athleteProfile.firstName;
		this.scoutingReport.playerDetails.lastName = this.athleteProfile.lastName;
		this.scoutingReport.playerDetails.position = this.athleteProfile.primaryPosition;
		this.scoutingReport.playerDetails.dominantFoot = this.athleteProfile.dominantSide;
		this.scoutingReport.playerDetails.dateOfBirth = this.athleteProfile.birthDate;
		this.scoutingReport.playerDetails.yearOfBirth = this.athleteProfile.birthDate.getFullYear();

		this.scoutingReport.contactInfo.firstName = this.athleteProfile.firstName;
		this.scoutingReport.contactInfo.lastName = this.athleteProfile.lastName;
		this.scoutingReport.contactInfo.email = this.athleteProfile.email;

		if( this.IsNotEmpty(this.playerOnTeam) ) {
			this.scoutingReport.playerDetails.jerseyNumber = Number(this.playerOnTeam.number);
		}

		if( this.IsNotEmpty(this.team) ) {
			this.scoutingReport.gameDetails.coachId = this.MyCoachId;
			this.scoutingReport.gameDetails.teamName = this.team.name;
		}
	}

	async onChangeAthleteLink(athleteLink: AthleteAutocompleteInfo) {
		// if it's the same athlete, do nothing
		if( athleteLink?.id === this.athleteId ) return;

		this.isAthleteLinking = true;
		if( this.IsNotEmpty(athleteLink?.id) ) await new Promise(r => setTimeout(r, 500));

		// reset the page contents
		this.athleteId = undefined;
		this.athleteProfile = new AthleteProfileModel();
		this.scoutingReport = new ScoutingReportModel();
		this.playerOnTeam = undefined;
		this.teamId = undefined;
		this.team = undefined;

		// load the updated athlete
		if( this.IsNotEmpty(athleteLink) ) {
			this.athleteLink = athleteLink;
			this.athleteId = athleteLink.id;
		}
		await this.loadAthlete();

		this.isAthleteLinking = false;
	}
	async onReportChanged() {
		this.reportChanged = true;
	}
	async onCompetitiveYouthChanged() {
		this.scoutingReport.playerDetails.competitiveLevel = undefined;
	}
}
