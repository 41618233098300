
import { Component, Prop, Mixins } from 'vue-property-decorator';
import CircledAvatar from '../ui/CircledAvatar.vue'
import CountryFlag from '../ui/CountryFlag.vue'
import CoachStaffPosition from '../../components/profile/coach/CoachStaffPosition.vue';
import { TeamModel } from '../../models/team';
import { country } from '../../pipes/country.pipe';
import { CoachProfileModel } from '../../models/coach/CoachProfileModel';

import {imagesStore} from '../../store';

import { CoachCreateResume } from '@/../types/constants/web_client_user.routes';
import { Route } from 'vue-router';
import { VuetifyMixin } from '@/mixins';
import { BAIconsMixin } from '../../mixins';

@Component({
	components: { CountryFlag, CircledAvatar, CoachStaffPosition },
})
export default class CoachProfileCard extends Mixins(VuetifyMixin, BAIconsMixin) {
	country = country;

	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ default: false, type: Boolean }) hideTeams: boolean;
	@Prop({ default: false, type: Boolean }) myProfile: boolean;
	@Prop({ required: true }) coach: CoachProfileModel;
	@Prop({ required: true }) teams: TeamModel[];
	@Prop({ default: 'baColorVibrantBlue' }) color: string;

	get CoachResumeRoute(): Partial<Route>{
		return {
			name: CoachCreateResume
		}
	}

	get ImageUrl(): string | null{
		// since we need to get a coach object anyway for this component we won't bother checking the image cache but will
		// add the coach's image to the cache
		if (this.coach && this.coach.pictureUrl) {
			imagesStore.setCache({id: this.coach.id, url: this.coach.pictureUrl});
			return this.coach.pictureUrl;
		} else {
			return null;
		}
	}
	get CoachId(){
		return this.coach.id;
	}
	get CoachName(){
		if(this.coach){
			return `${ this.coach.firstName } ${ this.coach.lastName }`;
		}
		return '';
	}

	get ShowSetupProfile(){
		return this.coach && !this.coach.resume;
	}

	CoachProfileRoute(CoachId: string){
		this.$router.push({ name: 'CoachProfile', params: { coachId: CoachId} })
	}

}
