import { CrudApi } from './CrudApi';
import { GameReportModel } from "@/models";

class GameReportApi extends CrudApi<GameReportModel> {
	constructor() {
		super('game-report', (obj) => new GameReportModel(obj));
	}
}

export const gameReportApi = new GameReportApi();
